import React from "react"
import { Flex,Spinner,Heading } from "@chakra-ui/react"

const LoadingSpinner=({title})=>{
    return(
       <Flex w="full" h="full" direction="column" gridRowGap={8} alignItems="center" justifyContent="center" bg='#F3F5F9' py={8}>
           <Spinner
             width={'80px'} height={'80px'}
             thickness={"6px"}
             speed="0.65s"
             emptyColor={'#E2E8F0'}
             color={'#25855A'}
            />
            <Heading color={'#000'} size="md">{title}</Heading>
        </Flex>
    )
}

export default LoadingSpinner