import {
  TableContainer,Table,Thead,Th,Tr,Td, Tbody,
  Flex,HStack,Box,
  Checkbox,Image,Text,Spinner,IconButton,Tooltip,
} from "@chakra-ui/react"
import React,{useState,useEffect,useRef, useContext} from "react"
import norecord from '../../../assets/norecord.png'
import {WorkspaceContext} from '../../commonComponents/Layout/Layout'
import {MdOutlineModeEditOutline, MdOutlineDeleteOutline,MdOutlineRemoveRedEye} from 'react-icons/md'
import Pagination from "app/commonComponents/Pagination"
import { useSharepointContext } from "./context/SharepointContext"

const SharepointTable = ({ searchByName }) => {
  
  const [isCheckAll, setIsCheckAll] = useState(false)
  const { workspaceMetadata } = useContext(WorkspaceContext) 
  const {globalSiteState,handleEditSite,handlePreviewSite,handleDeleteSite,handleCheckAllSites,handleCheckboxChange,updateCurrentPage,updateItemsPerPage} = useSharepointContext()
  const records=globalSiteState.sites
  
   
    const pages = []
    const recordswithWorkSpace=records.filter((element)=>{
      if(workspaceMetadata==='noWorkSpace') return element.workspace===null
      else return element.workspace===workspaceMetadata
    })
    const recordsToBeDisplayed = recordswithWorkSpace.filter((data)=>{
     return ((data.crawlStatus==='Published'||data.crawlStatus==='Unpublished')&& ((searchByName==="")||(data.docName.toLowerCase().includes(searchByName.toLowerCase()))))
      
    })
    const pagesLength=Math.max(1,Math.ceil(globalSiteState.totalRecords / globalSiteState.itemsPerPage))
    for (let i = 1; i <=pagesLength ; i++) {
    pages.push(i)
    }
    const commonProps = {borderRadius:'2px', px:'5px', py:'2px',fontSize:'11px'}
    const attributeProps={...commonProps, color:'#617182', bg:'#E5E5E5'}

  return (
    <React.Fragment>
      <TableContainer className="sharepoint">
        <Table>
          <Thead>
            <Tr>
              <Th>
                <Checkbox
                  sx={{
                  '& .chakra-checkbox__control[data-checked]': {
                    bg: '#2563EB',
                    borderColor: '#2563EB',
                  }}}
                  isChecked={(isCheckAll && recordsToBeDisplayed.length===0) || (recordsToBeDisplayed.length > 0 && recordsToBeDisplayed.every(record=>globalSiteState.checkedSites.some(file=>record.id===file)))}
                  onChange={(e)=> {
                    setIsCheckAll(!isCheckAll)
                    handleCheckAllSites(e)
                    }}
                />
              </Th>
              <Th>Title</Th>
              <Th>URL</Th>
              <Th>Created At</Th>
              {/*<Th>Update Frequency</Th>}
              {/* <Th>Status</Th> */}
              <Th>Last Update</Th>
              <Th>Azure Tenant ID</Th>
              {/* <Th>Description</Th> */}
              {/* <Th>Restricted Domain</Th> */}
              <Th textAlign='center'>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {
            !globalSiteState.isFetching&&(recordsToBeDisplayed.length!==0)?recordsToBeDisplayed.map((item)=>{
              return(
                  <Tr key={item.id}>
                  <Td> 
                    <Checkbox 
                    sx={{
                      '& .chakra-checkbox__control[data-checked]': {
                        bg: '#2563EB',
                        borderColor: '#2563EB',
                      }}}
                    value={item.id}
                    isChecked={globalSiteState.checkedSites.includes(item.id)}
                    onChange={handleCheckboxChange} />
                  </Td>
                  <Td maxWidth='200px' textOverflow='ellipsis' overflow='hidden' title={item.label} >{item.label}</Td>
                  <Td maxWidth='200px' textOverflow='ellipsis' overflow='hidden' title={item.docName} >{item.docName}</Td>
                  <Td>{item.createdAt}</Td>
                  {/* <Td>{item.frequency}</Td> */}
                  {/* <Td><Text {...bagdeProps} as='span'>{e.crawlStatus}</Text></Td> */}
                  {/* <Td>{e.crawlStatus==='Published'?e.updatedAt:<Button {...lastUpdateProps} onClick={()=>console.log('Called Update Now')}>Update Now</Button>}</Td> */}
                  <Td>{item.updatedAt}</Td>
                  <Td>{item.sharepointAzureTenantId}</Td>
                  {/* <Td textOverflow='ellipsis' overflow='hidden' maxWidth='500px' title={item.description}>{item.description?item.description:'-'}</Td> */}
                 {/* <Td>                     
                    <Switch id='restrict-domains' _checked={{ "& .chakra-switch__track": { background: "#1E90FF"}}} colorScheme='blue' value={item.id} isChecked={entriesWithRestrictedDomains.includes(item.id)} onChange={toggleRestrictedDomain}/> 
                 </Td> */}
                  <Td textAlign='center'>
                    <HStack justify='center' p='0' m='0' >
                      <Tooltip placement='top' label='Edit site' p='0' m='0' ><IconButton aria-label="Edit site" variant='ghost' onClick={()=>handleEditSite(item.id)} icon={<MdOutlineModeEditOutline color="#94A3B8"/>} fontSize='21px' p='0' m='0' ></IconButton></Tooltip>
                      <Tooltip placement='top' label='Delete site' p='0' m='0' ><IconButton aria-label="Delete site" variant='ghost' onClick={()=> handleDeleteSite(item.id)} icon={<MdOutlineDeleteOutline color="#94A3B8"/>} fontSize='21px'  p='0' m='0' ></IconButton></Tooltip>
                      <Tooltip placement='top' label='Preview site' p='0' m='0' ><IconButton aria-label="Preview site" variant='ghost' onClick={()=>handlePreviewSite(item.id)} icon={<MdOutlineRemoveRedEye color="#94A3B8"/>} fontSize='21px'  p='0' m='0' ></IconButton></Tooltip>
                    </HStack>
                  </Td>
                  </Tr>
              )
              })
              :(!globalSiteState.isFetching&&recordsToBeDisplayed.length==0)?(
                <Tr>
                  <Td colSpan={9}>
                    <Flex flexDir="column" justifyContent='center' alignItems='center' >
                      <Image width='140px' height='140px' src={norecord} alt='no records'/>
                      <Text as='h5' fontSize='18px' fontWeight='500' my='20px' textAlign='center' >No records found!</Text>
                      <Text>you can add new SharePoint URL by clicking on the SharePoint Integration button on the top right.</Text>
                    </Flex>
                  </Td>
                </Tr>
              ):
              (
                <Tr>
                    <Td colSpan={9}>
                        <Flex flexDir="column" justifyContent='center' alignItems='center' >
                          <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='#2ac769' size='xl' />
                          <Text my='2' data-testid='fetching_sites'>Fetching Sites ...</Text>
                        </Flex>
                      </Td>
                  </Tr>
              )
            }
          </Tbody>
        </Table>
      </TableContainer>
      <Box display='flex' justifyContent='space-between' width='100%' mt="15px" >
      <Box display='flex' alignItems="center" fontSize="14px" color="#6B7280" fontWeight="400" lineHeight="normal">
          Show
          <Box border='1px solid #D1D5DB' borderRadius='4px' mx='3'>
          <select onChange={(e)=>updateItemsPerPage(e.target.value)} style={{borderRadius:"4px", padding:"4px 8px"}}>
              <option value='100'>100</option>
              <option value='200' >200</option>
          </select>
          </Box>files per page</Box>
        <Pagination totalPages={pagesLength} currentPage={globalSiteState.currentPage} onPageChange={updateCurrentPage} />
      </Box>
    </React.Fragment>
  )
}

export default SharepointTable