import config from '../config/index'
import { useState,useContext, createContext,useEffect } from 'react'
import { ServiceHelper } from 'common'
import { useMutation,useQueryClient,useQuery } from 'react-query'
import AppContext from 'AppContext'

const knowledge_source_map = {
    'Documents': 'Documents',
    'Sharepoint connect':'SharePoint Integration',
    'Crawled URLs':'Crawl URL',
    'Knowledge articles':'Knowledge Articles'
}

const ServicesContext=createContext(null)
const initialState = {
  refetchTotalRecords: true,
  refetchArticles:true,
  refetchDocuments:true,
  refetchUrls: true,
  totalRecords:[],
  articleTabState:{
      page:'list',
      selectedArticle:null
  },
  // selectedGap:null,
  fixedGap:false
}
export const ServicesContextProvider=({children})=>{

  const isLocal = config.localTest
  const { identity } = useContext(AppContext)
  const [globalState, setGlobalState] = useState(initialState)
  const [categories, setCategories] = useState([])
  const [simpleTags, setSimpleTags] = useState([])
  const [dimensions, setDimensions] = useState([])
  const [userAttributes,setUserAttributes]=useState([])
  const [commonAttributes, setCommonAttributes] = useState([])
  const [disabledSources, setDisabledSources] = useState([])
  const [autoComplete, setAutoComplete] = useState(false)
  const [genAIConfig, setGenAIConfig] = useState(null)

  // -------------------- Global api call -----------------------//

  const apiCall=({options,payload})=>{
    const reqOptions={
      method:options.method || 'POST',
    }
    if(payload) reqOptions.data = payload
    if(options.headers) reqOptions.headers = options.headers
    if(options.query) reqOptions.query = options.query

    if(isLocal&& (options.url.startsWith('InputFiles')||options.url.startsWith('job')||options.url.startsWith('aiquery'))){
      reqOptions.url=`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_BASE_URL}`
      reqOptions.reqPath = options.url
      reqOptions.headers={...reqOptions.headers, 
          "x-user-payload":JSON.stringify({"email":identity.profile.email,"username":identity.profile.preferred_username
        ,"firstName":identity.profile.given_name,"lastName": identity.profile.family_name,"userId": identity.profile.userId,"tenantUid":identity.profile.tenantUid,"tenantId":identity.profile.tenantUid,"roles":identity.profile.tenantRoles}),
          "x-tenantId":identity.profile.tenantUid
        }   
    }
    else{     
      reqOptions.reqPath = `${(options.url.startsWith('InputFiles')|| options.url.startsWith('job'))|| options.url.startsWith('aiquery')?'ml/':''}${options.url}`
    }
    return ServiceHelper.prepAndAsyncCall(reqOptions).then(res=>res.data)
  }

  const createUpdateMutation=useMutation(apiCall,{
    onSuccess:({data})=>{
     return data
    },
    onError:(error)=>{
     return error
    }
  })
    const getTotalRecords=()=>{
        return apiCall({
         options: {
          url: `InputFiles/getFiles`,
          method: "GET",
          variables:'sites',
          query:`$filter=(status eq 'Published' or crawlStatus eq 'Published')&$skip=0&$top=100000&$orderby=createdAt desc`
          },
       })
      }
    const {data} = useQuery(['totalrecords'],getTotalRecords,{enabled:globalState.refetchTotalRecords})

  useEffect(() => {
        if(data){
            setGlobalState((prev)=>({...prev,totalRecords:data.data.rows,refetchTotalRecords:false}))
        }
    }, [data])
  
useEffect(()=>{
  apiCall({
    options: {
      url: `InputFiles/categories`,
      method: "GET",
    },
  }).then(data=>{
    setCategories(data.hierachyData)
    setSimpleTags(data.simpleData)
    setDimensions(data.dimensions)
  })
  .catch(error => console.log(`There was an error in fetching categories: `, error.message))
},[])

useEffect(()=>{
  apiCall({
    options: {
      url: `UserAttribute/findAndCountAll`,
      method: "GET",
      query:`$orderby=updatedAt%20desc&$skip=0&$top=100`
    },
  }).then(data=>{
    const activeAttributes=data.rows.filter((item)=>{
      return (item.status === "Active" && item.key !== "team" && item.key !== "defaultTeam")
    })
    setCommonAttributes(activeAttributes)
    })
  .catch(error => console.log(`There was an error in fetching user attributes: `, error.message))
},[])

// useEffect(()=>{
//     apiCall({
//       options: {
//         url: `InputFiles/bot_config_attributes`,
//         method: "GET",
//       },
//     }).then(data=>{
//       const botAttributeKeys = data?.botConfigAttributes[0].properties?.userData.map(item => item.userAttribute)
//       const filteredAttributes = userAttributes.filter(userAttr =>botAttributeKeys.includes(userAttr.key))
//       setCommonAttributes(filteredAttributes)
//     })
//     .catch(error => console.log(`There was an error in fetching user attributes: `, error.message))
// }, [userAttributes])
  
  const createOrUpdateAsync =async (options,payload)=>{
    try{
      return await createUpdateMutation.mutateAsync({options,payload})
    }
    catch(error){
      return error
    }
  }
 
    const fetchAiConfigData = () => apiCall({
        options: {
        url: `/botConfig/findWithParentAndCountAll`,
        method: "GET",
        query:`$filter=status eq 'Published' and (fullName eq 'uiGenAIConfig' or fullName eq 'answer_strategy' or fullName eq 'bot_tonality' or fullName eq 'knowledge_threshold')`
        },
    })
    
  const { data: genAiConfigData } = useQuery(["getAiConfigData"], fetchAiConfigData, { refetchOnWindowFocus: true })
  
  useEffect(() => {
    if (genAiConfigData) {

      setGenAIConfig({
        enabledSources: genAiConfigData.find(config => config.fullName === 'uiGenAIConfig').properties?.sections.find(section => section.type === 'draggableDiv').htmlElement.filter(source => source.data.toggleValue).map(item => ({name:item.data.title,threshold:item.data.threshold})),
        disabledSources: genAiConfigData.find(config => config.fullName === 'uiGenAIConfig').properties?.sections.find(section => section.htmlElement.some(element => element.data.title in knowledge_source_map)).htmlElement.filter(section => section.data.title in knowledge_source_map && !section.data.toggleValue).map(section => knowledge_source_map[section.data.title]),
        threshold: genAiConfigData.find(config => config.fullName === 'knowledge_threshold').properties * 100,
        bot_tonality: genAiConfigData.find(config => config.fullName === 'bot_tonality').properties,
        answer_strategy: genAiConfigData.find(config => config.fullName === 'answer_strategy').properties
      })
      setDisabledSources(genAiConfigData.find(config=>config.fullName==='uiGenAIConfig').properties.sections.find(section => section.htmlElement.some(element => element.data.title in knowledge_source_map)).htmlElement.filter(section=>section.data.title in knowledge_source_map && !section.data.toggleValue).map(section=>knowledge_source_map[section.data.title]))
      setAutoComplete(genAiConfigData.find(config => config.fullName === 'uiGenAIConfig').properties?.sections?.find(item => item?.header === 'Auto Complete')?.htmlElement?.some(element => element?.data?.key === 'knowledgeArticles2' && element.data.toggleValue === true) || false)
    }
  }, [genAiConfigData])
  
  const updateArticles = () => {
    setGlobalState(prevState=>({...prevState,refetchArticles:false}))
  }

  const updateCrawlUrls = () => {
    setGlobalState(prevState=>({...prevState,refetchUrls:false}))
  }

  const updateDocuments = () => {
    setGlobalState(prevState=>({...prevState,refetchDocuments:false}))
  }
  const updateAudienceAttributes = audienceAttributes => {
    setGlobalState(prevState=>({...prevState,audienceAttributes}))
  }
  
  const triggerRefetchArticles = () => {
    setGlobalState(prevState=>({...prevState,refetchArticles:true}))
  }
  
  const triggerRefetchDocuments = () => {
    setGlobalState(prevState=>({...prevState,refetchDocuments:true}))
  }
  
  const triggerRefetchUrls = () => {
    setGlobalState(prevState=>({...prevState,refetchUrls:true}))
  }
  
   const triggerRefetchTotalRecords = () => {
    setGlobalState(prevState=>({...prevState,refetchTotalRecords:true}))
  }
  const openArticleDetailPage = (id) => {
    setGlobalState(prevState=>({...prevState,selectedTab:'article',articleTabState:{
        page:'detail',
        selectedArticle:id
    }}))
  }
  
  const openTab = tabName => {
    setGlobalState(prevState=>({...prevState,selectedTab:tabName}))
  }
  
  const updateSelectedTab = tabName => {
    setGlobalState(prevState=>({...prevState,selectedTab:tabName}))
  }
  const setSelectedGap = (id)=>{
    setGlobalState(prevState => ({...prevState,selectedGap:id}))
}
//   const onFixUploadGap=async (id,FixingMethod)=>{
//     const data={
//         failedUtteranceOperationId:id,
//         mappedTo:null,
//         qnaAppId: null,
//         trainedAs: null,
//         trainingStatus:FixingMethod,
//         type:"qna",
//         status:'Deleted'
//     }
//     const response=await createOrUpdateAsync({url:'botmgmt-service/train/updateTrainStatus',method:'POST'},data)
//     if(response){
//       setGlobalState((prev)=>({...prev,fixedGap:true}))
//        console.log("trained succesfully")
//     }
//     else{
//       debugger
//       console.log("error occured while training")
//     }
// }
// const selectedGapItem=globalState.selectedGap
// const fixedGap=globalState.fixedgap

  const getAIConfigData = () => {
    return genAIConfig
  }
  const getAudience=()=>{
    return apiCall({
     options: {
      url: `catalog/audiences/list?%24select=*`,
      method: "GET"
      },
   })
  }
  const {data:audience} = useQuery(['audiencesRecords'],getAudience,{enabled:globalState.refetchTotalRecords})
  const audiencesRecords=audience?.rows

return(
    <ServicesContext.Provider value={{createOrUpdateAsync,apiCall,globalState,updateCrawlUrls,updateArticles,updateDocuments,updateAudienceAttributes,triggerRefetchArticles,triggerRefetchDocuments,triggerRefetchUrls,triggerRefetchTotalRecords,openArticleDetailPage,updateSelectedTab,openTab,setSelectedGap,categories,simpleTags,commonAttributes,userAttributes,dimensions,disabledSources,autoComplete,getAIConfigData,audiencesRecords}} >
    {children}
    </ServicesContext.Provider>
  )
}
export const useServicesContext=()=>{
  return useContext(ServicesContext)
}
