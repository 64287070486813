import { Box, Flex } from "@chakra-ui/react"
import Sidebar from "../Sidebar/Sidebar"
import Header from "../Header/Header"
import React, { useEffect, useState, useContext } from "react"
import AppContext from "AppContext"
import axios from "axios"
import LoadingSpinner  from "../utils/LoadingSpinner"
import Footer from '../Footer/Footer'
import { ServicesContextProvider } from 'services/apiServices'
export const WorkspaceContext = React.createContext()
 
const Layout = (props) => {
  const {logout}=props
  const { cdnUri, identity,appData } = useContext(AppContext)

  const [workspaceMetadata, setWorkspaceMetadata] = useState("")
  const [workspaceOptions, setWorkspaceOptions] = useState([])
  const [loader, setLoader] = useState(true)
  const [sidebarCollapsed,setSidebarCollapsed]=useState(false)
  const [attributes,setAttributes]= useState({})
  const [active,setActive] = useState(null)
  const [ascConditions,setAscConditions]= useState(false)
  const [ascLoader,setAscLoader]= useState(false)
  const apiUrl = sessionStorage.getItem("apiUrl")
  const userId=identity?.profile?.userId
  const tenantId=identity?.profile?.tenantId
 
 
 
  useEffect(() => {
    if (!ascConditions && (Object.keys(attributes).length > 0 || (identity.roles.includes('ASC Admin') || identity.roles.includes('asc_admin')))) {
      setLoader(true)
      setAscConditions(true)
      let defaultTenantTeam
      if (!attributes?.defaultTeam) {
        let parentTenant = localStorage.getItem('tenant')
        defaultTenantTeam = parentTenant ? JSON.parse(parentTenant)?.parentTenant?.toLowerCase() || '' : ''
        // let defaultTenantTeam = JSON.parse(localStorage.getItem('tenant')).parentTenant.toLowerCase()
      }
      setLoader(true)
      setAscLoader(true)
      axios.post(`${apiUrl}/workspace/getFilterItemsForUser`,
        {
          wsType: "qna",
          attributes: attributes,
          ascFlag: identity.roles.includes('ASC Admin')
        },
        {
          headers: {
            Authorization: `Bearer ${identity.token}`,
            "x-tenantId": identity.profile.tenantUid,
          },
        }
      )
        .then((res) => {
          if ((identity.roles.includes('ASC Admin') || identity.roles.includes('asc_admin')) && res?.data?.rules) {
            res.data.rules = res.data.rules.map(elem => {
              let props = {}
              elem.properties.forEach(ele => {
                ele.values.forEach(el => {
                  props[ele.key] = el.value
                })
              })
              elem.props = props
              return elem
            })
            res.data.rules = res?.data?.rules?.filter(item => {
              return item?.properties?.some(property => property?.key === 'team')
            })
          }
          if (res.data.rules.length > 0) {
            setWorkspaceOptions(res.data.rules)
            setWorkspaceMetadata(res.data.rules[0].name)
            const defaultTeamIndex = res.data.rules.findIndex(option => {
              const defaultTeam = attributes?.defaultTeam?.[0] ?? defaultTenantTeam
              return option.props.team === defaultTeam
            })
            if (defaultTeamIndex && defaultTeamIndex !== -1) {
              setLoader(true)
              let updatedWorkspaceOptions = [...res.data.rules]
              updatedWorkspaceOptions.splice(defaultTeamIndex, 1)
              const defaultTeam = res.data.rules.filter(item =>
                attributes?.defaultTeam?.some(element => element === item.props.team) || item.props.team === defaultTenantTeam
              )
              updatedWorkspaceOptions.unshift(defaultTeam[0])
              setWorkspaceOptions(updatedWorkspaceOptions)
              setWorkspaceMetadata(defaultTeam[0].name)
              setLoader(false)
            }
            setAscLoader(false)
          }
          setLoader(false)
        })
        .catch((err) => {
          setAscLoader(false)
          setLoader(false)
          console.log("error workspace", err)
        })
    }
  }, [attributes])
 
    useEffect(()=>{
      axios.get(`${apiUrl}/role/users/${userId}?tenantId=${tenantId}` ,{
        headers: {
          Authorization: `Bearer ${identity.token}`,
          "x-tenantId": identity.profile.tenantUid
        }
      }).then((res)=>{
          res?.data?.attributes && setAttributes(res.data?.attributes)
          setLoader(false)
      }).catch((err)=>{
          setLoader(false)
          console.log("err",err)
      })
    }, [])
  const handleWorkpaceChange = (e) => {
    setWorkspaceMetadata(e.target.value)
  }
  const handleSidebar=()=>{
    setSidebarCollapsed(!sidebarCollapsed)
  }
  return (
    <WorkspaceContext.Provider value={{ workspaceMetadata, workspaceOptions,active,setActive}}>
    <ServicesContextProvider>
        <Flex className="main-page" flexDirection="column" w="100vw" >
          <Header handleWorkpaceChange={handleWorkpaceChange} workspaceOptions={workspaceOptions} workspaceMetadata={workspaceMetadata} cdnUri={cdnUri} identity={identity} sidebarCollapsed={sidebarCollapsed} logout={logout} />
          <Flex>
            <Sidebar appData={appData} handleSidebar={handleSidebar} sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} identity={identity} />
              <Box minW={sidebarCollapsed?"calc( 100% - 60px)":"calc( 100% - 243px)"}  h='calc(100vh - 45px)' >
                <Box bg="#eff5fe" p="15px"  minH="calc( 100% - 41.5px )" >
                  {workspaceOptions.length !== 0 && !loader ? (
                    <Box bg="#fff" p='15px' borderRadius='15px' maxH='calc(100vh - 118px)' overflow='auto' >{props.children}</Box>
                  ) :identity?.roles?.includes('ASC Admin') ? (workspaceOptions.length === 0 || loader) : workspaceOptions.length === 0 && loader ?  (
                    <Box>
                    <LoadingSpinner title="Loading..." />
                  </Box>
                  ) : !ascLoader ? (
                  <Box textAlign="center" color="red">
                    You are not part of any workspace that has access to this
                    feature. Please contact your Tenant Admin
                  </Box>
                ):
                <Box>
                <LoadingSpinner title="Loading..." />
              </Box>}
              </Box>
            <Footer/>
           
          </Box>
        </Flex>
        </Flex>
      </ServicesContextProvider>
    </WorkspaceContext.Provider>
  )
}
export default Layout