import React, { useState,useContext } from "react"
import {Box,Text, Divider, Flex,Stack, HStack, InputGroup, Input, Select,Button,Textarea,Image} from "@chakra-ui/react"

import {TbCapture } from "react-icons/tb"
import { AiOutlinePlusCircle } from "react-icons/ai"

import { nanoid } from '@reduxjs/toolkit'
import back from '../../../assets/back.png'
import { useServicesContext } from "services/apiServices"
import { useSharepointContext } from "./context/SharepointContext"
import { ArrowBackIcon } from "@chakra-ui/icons"
import { SmallCloseIcon } from "@chakra-ui/icons"

const SharepointCreatePage = () => {

    const { createOrUpdateAsync,audiencesRecords } = useServicesContext()
    const {globalSiteState,closeDrawer,setDrawerLoading,onAddSiteSuccess, onAddSiteFailure,onEditSiteSuccess,onEditSiteFailure} = useSharepointContext()
    const initialInputState = {
        title:'',
        description:'',
        client_id:'',
        client_secret:'',
        azure_tenant_id:'',
        frequency:'No update',
        site_url:''
    }
    const [listKey,setListKey] = useState('')
    const [audienceValue,setAudienceValue] = useState('')
    const [audienceData, setAudienceData] = useState([])
    const [audienceOptions,setAudienceOptions] = useState(audiencesRecords|| [])
    const page = globalSiteState.drawerState.page

    const siteToEdit = page === 'edit' ? globalSiteState.sites.find(site => site.id === globalSiteState.selectedSite) : null
    const [formInputs, setFormInputs] = useState(page === 'edit' ? {title:siteToEdit.label,description:siteToEdit.description} :initialInputState)
    const [formInputErrors, setFormInputErrors] = useState({})
     const handleInputChange = (e) =>{
        setFormInputs({...formInputs,[e.target.name]:e.target.value})
        if(e.target.name in formInputErrors && formInputErrors[e.target.name]!=='' )
            setFormInputErrors({...formInputErrors,[e.target.name]:''})
    }

    const checkInputErrors = () =>{
        const inputErrors = {}
        inputErrors.client_id= page==='edit' || formInputs.client_id.trim()!=='' ? '':'Client Id cannot be blank'
        inputErrors.client_secret = page==='edit' || formInputs.client_secret.trim()!=='' || globalSiteState.hasCertificate ? '' : 'Client Secret cannot be blank'
        inputErrors.azure_tenant_id = page==='edit' || formInputs.azure_tenant_id.trim()!=='' ?  '' : 'Azure Tenant Id cannot be blank'
        const existingSite = page==='edit' || globalSiteState.sites.find(site=>site.docName===(new URL(formInputs.site_url)).origin)
        inputErrors.site_url= page==='edit'?'' :formInputs.site_url.trim()==='' ? 'SharePoint Site Url cannot be blank' : !isValidUrl(formInputs.site_url.trim()) ? 'Please enter a valid Url' : existingSite ? 'SharePoint Site already exists' :''

        const validTitle=isValidTitle(formInputs.title.trim())
        inputErrors.title= formInputs.title.trim()==='' ? 'Title cannot be blank' : !validTitle? 'Please enter a valid title' : ''

        return inputErrors
    }

    const isValidUrl = (url) => {
        const urlRegex = /^(?!^[0-9])(https?|ftp):\/\/([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/
     
        if (url.startsWith("http://www.") || url.startsWith("https://www.") || url.startsWith("http://") || url.startsWith("https://")) {
            return urlRegex.test(url)
        }
        else if (!url.startsWith("http://") || !url.startsWith("https://")) {   
            return urlRegex.test("https://" + url)
        }
    }

    const isValidTitle=(title)=>{
        const regexPattern=/^[a-zA-Z0-9_ ]*$/
        return regexPattern.test(title)
    }

    const isPreviewDisabled = () => {
        return page==='edit'?formInputs?.title?.trim()==='':globalSiteState.hasCertificate?formInputs?.title?.trim()==='' || formInputs?.site_url?.trim()===''||formInputs?.client_id?.trim()==='' ||formInputs?.azure_tenant_id?.trim()==='':formInputs?.title?.trim()==='' || formInputs?.site_url?.trim()==='' || formInputs?.client_id?.trim()==='' || formInputs?.client_secret?.trim()==='' || formInputs?.azure_tenant_id?.trim()==='' ?true:false
    }
    //Handle Adding a domain to restrict
    const handleAddRestrictDomains=()=>{

        if(!formInputs.restrictDomains)
            formInputs.restrictDomains=[]
         const filteredDomains=formInputs.restrictDomains.filter((e)=>{
          return e===formInputs.domain
        })
        if(!isValidUrl(formInputs.domain)){
            setFormInputErrors({...formInputErrors,domain:`'${formInputs.domain}' is not a valid URL. Please enter a valid URL`})
            setFormInputs({...formInputs,domain:''})
        }
        else if(filteredDomains.length>0){
            setFormInputErrors({...formInputErrors,domain:'Domain already exists'})
            setFormInputs({...formInputs,domain:''})
        }
        else{
          setFormInputs({...formInputs,restrictDomains:[...formInputs.restrictDomains,formInputs.domain],domain:''}) 
        }
    }

    //Handle Remving domain to restrict
    const handleRemoveRestrictDomains = (domain) =>{
        setFormInputs({...formInputs,restrictDomains:formInputs.restrictDomains.filter(item=>item!==domain)}) 
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const inputErrors = checkInputErrors()
        if(Object.values(inputErrors).every(value=>value==='')){
            const baseUrl = formInputs?.site_url?.trim().startsWith("http:")||formInputs?.site_url?.trim().startsWith("https://")?formInputs?.site_url?.trim() :`https://${formInputs?.site_url?.trim()}`
            const options = { url: 'InputFiles/sharepoint', method: 'POST' } 
            const payload = {}
            if (page === 'create') {
                payload.operation = 'add_sharepoint_site'
                payload.sharepoint_site_url = baseUrl
                payload.client_id = formInputs.client_id.trim()
                payload.azure_tenant_id= formInputs.azure_tenant_id.trim()
                if (!globalSiteState.hasCertificate) payload.client_secret = formInputs.client_secret.trim()
                else payload.certificateProps = globalSiteState?.thumbPrint
            } else {
                payload.operation = 'update_sharepoint_site'
                payload.siteId = siteToEdit.id
                payload.azure_site_url = siteToEdit.docName
                payload.label = formInputs.title
                payload.frequency = siteToEdit.frequency
                payload.description= formInputs.description
            }

            // const urlBody = page === 'create' ?
            //     { operation: 'add_sharepoint_site', sharepoint_site_url: baseUrl, client_id: formInputs.client_id.trim(), client_secret: formInputs.client_secret.trim(), azure_tenant_id: formInputs.azure_tenant_id.trim() }
            //     :
            //     {operation:'update_sharepoint_site',siteId: siteToEdit.id,azure_site_url:siteToEdit.docName,label:formInputs.title,frequency:siteToEdit.frequency,description:formInputs.description}
            setDrawerLoading(page === 'create' ?'Crawling SharePoint sites':'Saving SharePoint data')
            const response = await createOrUpdateAsync(options, payload)
            if (response.code === 200) {
                const newInputs = globalSiteState.hasCertificate ? {title:formInputs.title, description:formInputs.description,site_url:formInputs.site_url,azure_tenant_id:formInputs.azure_tenant_id,client_id:formInputs.client_id,frequency:formInputs.frequency}:formInputs
                 page === 'create' ?
                    onAddSiteSuccess({ previewData: response.data.sites.map(item => { return { ...item, crawlStatus: true } }), inputs: newInputs })
                    :onEditSiteSuccess()
            }
            else
                page === 'create' ? onAddSiteFailure(response.message): onEditSiteFailure(response.message)
        }else{
            setFormInputErrors(state=>({...state,...inputErrors}))
        }
        setFormInputs(initialInputState)
    }
    // -----------------------audience---------------------//
    const handleAudienceData = (event) => {
        event.preventDefault()
      
        setAudienceData((prevData) => {
          const updatedData = { ...prevData }
          const key = listKey === '' ? 'Whitelist' : listKey
          if (key === 'Whitelist' && updatedData['Blacklist']?.length > 0) {
            return prevData
            
          }
      
          if (key === 'Blacklist' && updatedData['Whitelist']?.length > 0) {
            // alert("You can't add values to BlackList while WhiteList has values.");
            return prevData
          }
          if (!updatedData[key]) {
            updatedData[key] = []
          }
          if (!updatedData[key].includes(audienceValue)) {
            updatedData[key].push(audienceValue)
          }
          setFormInputs({...formInputs,audience:updatedData})
          return updatedData
        })
        setAudienceOptions((prevOptions) =>{
          if (listKey === 'Whitelist' && audienceData['Blacklist']?.length > 0) {
            return prevOptions 
          }
          if (listKey === 'Blacklist' && audienceData['Whitelist']?.length > 0) {
            return prevOptions
          }
          else{
            return prevOptions.filter((item) => item.name !== audienceValue)
         }
  
        })
      
        setListKey('')
        setAudienceValue('')
      }
      
      
      const handleRemoveAudience = (key, value) => {
        setAudienceData((prevData) => {
          const updatedData = { ...prevData }
          const valueToBeDelete=value
          updatedData[key] = updatedData[key].filter((item) => item !== value)
          if (updatedData[key].length === 0) {
            delete updatedData[key]
          }
      
          return updatedData
        })
      
        setAudienceOptions((prevOptions) => [...prevOptions, { name: value }])
      }
    return (
        <Box>
        <Flex justifyContent="space-between" alignItems="center" p='15px' color='#374151' borderBottom="1px solid #D1D5DB">
        <HStack alignItems='center' >
            {/* <Image src={back} alt="back" cursor='pointer' onClick={()=>closeDrawer(false)} /> */}
            <Box padding="2px" border="2px solid #2563EB" borderRadius="100px" display="inline-flex" onClick={()=>closeDrawer(false)} cursor="pointer"><ArrowBackIcon height="15px" width="15px" color="#2563EB" cursor="pointer"/></Box>
            <Text className="modal-title">SharePoint Integration</Text>
        </HStack>
        </Flex>
        <form onSubmit={handleSubmit} >
            <Stack p='10px 15px' fontSize='14px' color='gray.700'>
                <InputGroup flexDirection='column' style={{marginTop:"15px"}}>
                <Box display='flex' alignItems='start' >
                    <Text className="input-label">Title</Text><Text as='span' color='red' ml="2px" fontSize='14px'>*</Text>
                </Box>
                <Input placeholder="Title" style={{border:"1px solid #D1D5DB", color:"#111827", borderRadius:"6px"}} name='title' value={formInputs.title} color='#111827' onChange={handleInputChange} onBlur={()=>{}} />
                {formInputErrors.title!=='' &&<Text noOfLines={2} color='red' fontSize='12px'>{formInputErrors.title}</Text>}
                </InputGroup>
                <InputGroup flexDirection='column' style={{marginTop:"15px"}}>
                    <Text className="input-label">Description</Text>
                    <Textarea name='description' style={{border:"1px solid #D1D5DB", color:"#111827", borderRadius:"6px"}} rows={3} rezize='none' value={formInputs.description}  onChange={handleInputChange} />
                </InputGroup>
                {page === 'create' &&
                    <>
                        <InputGroup flexDirection='column' style={{marginTop:"15px"}}>
                            <Box display='flex' alignItems='start' ><Text className="input-label">Client Id</Text><Text as='span' color='red' ml="2px" fontSize='14px'>*</Text></Box>
                            <Input placeholder="Client ID" name='client_id' style={{border:"1px solid #D1D5DB", color:"#111827", borderRadius:"6px"}} value={formInputs.client_id} onChange={handleInputChange} />
                            {formInputErrors.client_id!=='' && <Text noOfLines={2} color='red' fontSize='12px' >{formInputErrors.client_id}</Text>}
                        </InputGroup>
                        {!globalSiteState.hasCertificate &&
                            <InputGroup flexDirection='column' style={{marginTop:"15px"}}>
                                <Box display='flex' alignItems='start'>
                                    <Text className="input-label">Client Secret</Text><Text as='span' color='red' ml="2px" fontSize='14px'>*</Text>
                                </Box>
                                <Input placeholder="Client Secret" name='client_secret' style={{border:"1px solid #D1D5DB", color:"#111827", borderRadius:"6px"}} value={formInputs.client_secret} onChange={handleInputChange} onBlur={() => { }} />
                                {formInputErrors.client_secret !== '' && <Text noOfLines={2} color='red' fontSize='12px' >{formInputErrors.client_secret}</Text>}
                            </InputGroup>
                        }
                        <InputGroup flexDirection='column' style={{marginTop:"15px"}}>
                            <Box display='flex' alignItems='start' >
                                <Text className="input-label">Azure Tenant Id</Text><Text as='span' color='red' ml="2px" fontSize='14px'>*</Text>
                            </Box>
                            <Input placeholder="ID" name='azure_tenant_id' style={{border:"1px solid #D1D5DB", color:"#111827", borderRadius:"6px"}} value={formInputs.azure_tenant_id} onChange={handleInputChange} onBlur={()=>{}} />
                            {formInputErrors.azure_tenant_id!=='' && <Text color='red' noOfLines={2} fontSize='12px' >{formInputErrors.azure_tenant_id}</Text>}
                        </InputGroup>
                        <InputGroup flexDirection='column' style={{marginTop:"15px"}}>
                            <Box display='flex' alignItems='start' ><Text className="input-label">SharePoint Site Url</Text><Text as='span' color='red' ml="2px" fontSize='14px'>*</Text></Box>
                            <Input placeholder="URL" name='site_url' style={{border:"1px solid #D1D5DB", color:"#111827", borderRadius:"6px"}} value={formInputs.site_url} onChange={handleInputChange} />
                            {formInputErrors.site_url!=='' && <Text noOfLines={2} color='red' fontSize='12px' >{formInputErrors.site_url}</Text>}
                        </InputGroup>
                      {/*  <Box>
                        <Text my='2' fontWeight='500' fontSize='14px' minWidth='300px' >Audience</Text>
                        <Box width='100%'>
                          <Box display='flex' gap='20px'>
                            <Select onChange={(event)=>{setListKey(event.target.value)}} value={listKey} defaultValue='Whitelist' >
                              <option value='Whitelist' >Whitelist</option>
                              <option value='Blacklist' >Blacklist</option>
                            </Select>
                            <Select onChange={(event)=>{setAudienceValue(event.target.value)}} value={audienceValue} placeholder="Audience(s)" >
                              {
                                audienceOptions.map((item)=>{
                                  return <option key={item.name} value={item.name}>{item.name}</option>
                                })
                              }
                            </Select>
                            <Button onClick={handleAudienceData} disabled={!audienceValue}>Add</Button>
                          </Box>
                        </Box>
                        <Box>
                        {Object.entries(audienceData).map(([key, values]) => (
                        <Box display='flex' key={key} mt="10px" alignItems='center' flexWrap='wrap' width='fit-content'  gap={2} border={'1px solid #1e90ff'} borderRadius='5px' p='5px'>
                          <Text color='#000' fontWeight={'500'}>{key} Audience: </Text>
                          <Box display={'flex'} gap='10px'>
                            {values.map((value) => (
                              <Flex key={`${key}-${value}`} display='flex' alignItems='center' gap={1} backgroundColor='#e0e7ff' borderRadius='5px' p='5px'>
                                <Text fontSize='14px' color='#3730a3' >{value}</Text>
                                <Text display='flex' alignItems='center' cursor='pointer' color='#94a3b8' onClick={() => handleRemoveAudience(key, value)}>
                                  <SmallCloseIcon color="#000" size='14px' />
                                </Text>
                              </Flex>
                            ))}
                          </Box>
                          </Box>
                          ))}
                        </Box>
                      </Box>*/}
                        {/* <InputGroup mb='10px' flexDirection='column'>
                                <Box display='flex' alignItems='start' ><Text>Frequency of Updates</Text><Text as='span' color='red' fontSize='10px'>*</Text></Box>
                                <Flex alignItems='center' >
                                    <Box width='320px' >
                                    <Select name='frequency' value={formInputs.frequency} color='gray.900' onChange={handleInputChange} >
                                        <option  value='No update'>No update</option>
                                        <option style={{background:'#f7f7f7',cursor:'not-allowed',color:'#c4c4c4'}} disabled value='Quarterly'>Quarterly</option>
                                        <option style={{background:'#f7f7f7',cursor:'not-allowed',color:'#c4c4c4'}} disabled value='Monthly'>Monthly</option>
                                        <option style={{background:'#f7f7f7',cursor:'not-allowed',color:'#c4c4c4'}} disabled value='Weekly'>Weekly</option>
                                    </Select>
                                    </Box>
                                </Flex>
                            </InputGroup>
                            <InputGroup mb='5px' flexDirection='column'>
                                <Text >Restrict to domain</Text>
                                <Flex>
                                    <Input placeholder="Urls" fontSize='14px' color='gray.900' name="domain" onChange={handleInputChange} value={formInputs.domain} />
                                    <Button padding='8px' background='#1e90ff' disabled={formInputs.domain!=='' ?false:true}  cursor={formInputs.domain!=='' ?'pointer':'not-allowed'} borderRadius='5px' ms='5px' border='1px solid #1e90ff' color="#1e90ff"  height='40px' onClick={handleAddRestrictDomains}><AiOutlinePlusCircle  size={'24px'} color="#fff" /></Button>
                                </Flex>
                                <Box height='15px' >{formInputErrors.domain && <Text color='red' fontSize='12px' >{formInputErrors.domain}</Text>}</Box>
                                    <HStack>
                                    {
                                        formInputs.restrictDomains && formInputs.restrictDomains.map((item,index)=>{
                                        return(
                                            <Box display='flex' alignItems='center' flexWrap='wrap' key={index} my='1' >
                                            <Text fontSize='14px' color='gray.700' >{item}</Text>
                                            <Text display='flex' alignItems='center' mx='3' cursor='pointer' onClick={()=>{handleRemoveRestrictDomains(item)}} ><GrClose  size='10px' /></Text>
                                            </Box>
                                            )
                                        })
                                    }
                                </HStack>
                                </InputGroup>*/}       
                    </>            
                }   
                <Box display='flex' justifyContent='flex-end' paddingTop="10%" gap="10px" >
                <Button className="secondaryButton" onClick={()=>closeDrawer(false)}>
                    Cancel
                </Button>
                <Button
                    disabled={isPreviewDisabled()}
                    _disabled={{
                        _hover:{backgroundColor:"#E5E7EB",color:"#8993A1"},
                        backgroundColor:"#E5E7EB",
                        color:"#8993A1",
                        cursor:"not-allowed"
                    }}
                    _hover={{
                        backgroundColor:"#0047EB"
                    }}
                    type='submit'
                    minW="100px"
                    // leftIcon={page==='edit'?null:<TbCapture size='24px'/>}
                    bg="#2563EB"
                    color="#fff" fontSize='14px' fontWeight='500' >
                   { page==='edit'?'Save':'Preview'}
                </Button>
                </Box>          
            </Stack>
        </form>
        </Box>
    )
}

export default SharepointCreatePage